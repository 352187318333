<template>
    <div class="flex justify-space-around">
        <div class="w-160 mx-3">
            <v-autocomplete
                v-if="$can('view', 'staff_assessments')"
                dense single-line outlined clearable 
                hide-details
                v-model="dataFilter.staff"
                label="Assigned Worker"
                :items="workersByLocationProgram"
                @change="changeStaff"
            ></v-autocomplete>
        </div>
        <div class="w-160">
            <DateRangePicker
                :maxDate="null"
                :row="false"
                :wrap="false"
                :outlined="true"
                :dense="true"
                :solo="false"
                :single-line="true"
                :value="dateRangeValue"
                @input="filterHandlerValue"
            />
        </div>
    </div>
</template>
<script>
import DateRangePicker from '@/components/partials/DateRangePicker.vue'
import { mapState } from 'vuex'
import EpisodeTable from "@/components/episodes/partials/EpisodeTable"
import HttpMixin from "@/components/shared/mixins/httpMixin"
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import BackendHelpers from "@/components/shared/mixins/backendHelpers"
import camelcaseKeys from "camelcase-keys"
import GET_USERS from '@/graphql/queries/getUsers.gql'
import EpisodesSvg from '@/assets/icons/nav/icons_episodes.svg'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'

const DEFAULT_FILTER = {
    locationProgram: {
        locationId: null,
        locationProgramId: null
    },
    status: null,
    worker: null
}

export default {
    name: 'AssessmentFilter',
    mixins: [BackendHelpers, HttpMixin, ClientHelpers],
    components: { EpisodeTable, EpisodesSvg, LocationProgramFilter, DateRangePicker },
    data() {
        return {
            dataFilter: {
                startDate: null,
                endDate: null,
                locationProgramId: null,
                staff: null
            },
            dateRangeValue: [],
            staffList: [
                {
                    text: 'Staff',
                    value: null
                }
            ],

            users: [],
            filter: {
                ...DEFAULT_FILTER,
                locationProgram: {...DEFAULT_FILTER.locationProgram}
            },
        }
    },
    apollo: {
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        }
    },
    emits: ['setAssessmentFilter'],
    methods: {
        filterHandlerValue(value) {
            this.dateRangeValue = value
            if (value.length === 2) {
                (this.dataFilter.startDate = value?.[0] ? value?.[0] : null),
                    (this.dataFilter.endDate = value?.[1] ? value?.[1] : null),
                    this.$emit('setAssessmentFilter', this.dataFilter)
            }
        },
        changeStaff(value) {
            this.dataFilter.staff = value
            this.$emit('setAssessmentFilter', this.dataFilter)
        },
        changeSelect(value) {
            this.dataFilter.staff = value
            this.$emit('setAssessmentFilter', this.dataFilter)
        },
        loadFilter(query) {
            this.filter.worker = query.worker || DEFAULT_FILTER.worker
        },
        resetFiltersHandler() {
            this.filter.worker = null

            this.filterChangeHandler()
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
        }),
        workersByLocationProgram() {
            if (!this.filter.locationProgram.locationProgramId) return this.workersByLoggedInUser
            return this.workersByLoggedInUser.filter(worker =>
                worker.locationProgramIds.includes(this.filter.locationProgram.locationProgramId)
            )
        }
    }
}
</script>