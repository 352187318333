<template>
    <div class="flex justify-space-around items-center">
        <div class="w-150 mx-2">
            <v-select
                dense
                single-line
                hide-details
                outlined
                label="All staff"
                :items="statusList"
                @change="changeSelect"
            ></v-select>
        </div>
        <div class="w-160">
            <DateRangePicker
                @input="filterChangeHandler"
                :maxDate="null"
                :row="false"
                :wrap="false"
                :outlined="true"
                :dense="true"
                :solo="false"
                :single-line="true"
                :value="dateRangeValue"
            />
        </div>
    </div>
</template>
<script>
import DateRangePicker from '@/components/partials/DateRangePicker.vue'
import {format} from 'date-fns'
export default {
    name: 'StaffAssessmentFilter',
    components: {
        DateRangePicker
    },
    data() {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        return {
            dataFilter: {
                startDate: format(firstDay, 'Y-MM-dd'),
                endDate: format(lastDay, 'Y-MM-dd'),
                locationProgramId: null,
                assessmentStatus: null
            },
            dateRangeValue: [],
            statusList: [
                {
                    text: 'All staff',
                    value: 'ALL'
                },
                {
                    text: 'Staff with overdue assessments',
                    value: 'OVERDUE'
                },
                {
                    text: 'Staff with incomplete assessments',
                    value: 'INCOMPLETE'
                },
                {
                    text: 'Staff with completed assessments',
                    value: 'COMPLETE'
                }
            ]
        }
    },
    mounted() {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        this.dateRangeValue = [
            format(firstDay, 'Y-MM-dd'),
            format(lastDay, 'Y-MM-dd')
        ]
    },
    emits: ['setStaffAssessmentFilter'],
    methods: {
        filterChangeHandler(value) {
            this.dateRangeValue = value
            if (value.length === 2) {
                (this.dataFilter.startDate = value?.[0] ? value?.[0] : null),
                    (this.dataFilter.endDate = value?.[1] ? value?.[1] : null),
                    this.$emit('setStaffAssessmentFilter', this.dataFilter)
            }
        },
        changeSelect(value) {
            this.dataFilter.assessmentStatus = value
            this.$emit('setStaffAssessmentFilter', this.dataFilter)
        }
    }
}
</script>