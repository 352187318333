<template>
    <div class="c-alert__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            v-if="!loading"
            :headers="headers"
            :items="tableDataArr"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="alertList"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :no-data-text="emptyText"
            :no-results-text="emptyText"
        >
            <template class="" v-slot:item.staffName="{ item }">
                <div>
                    {{ item.staffName }}
                </div>
            </template>
            <template class="" v-slot:item.numCompleted="{ item }">
                {{ item.numCompleted }}
            </template>
            <template class="" v-slot:item.numIncomplete="{ item }">
                {{ item.numIncomplete }}
            </template>
            <template class="" v-slot:item.numOverdue="{ item }">
                <div class="flex">
                     <div :class="item.numOverdue === 0 ? `` : `bg-red-light text-red text-xs font-bold p-2 rounded leading-3`" >
                        {{ item.numOverdue }}
                    </div>
                </div>
            </template>
            <template  class="" v-slot:item.clientEpisodes="{ item }">
                {{ item.numClientsWithOpenEpisodes }}
            </template>
        </v-data-table>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Loader from '@/assets/loader.svg'

export default {
    name: 'StaffAssessmentTable',
    components: {Loader},
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        headers: {
            type: Array,
            default: () => []
        },
        source: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No active staff assessments found',
            duration: 3000,
            type: '',
            message: '',
            page: 1,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            clientId: '',
            footerProps: {
                'items-per-page-options': [5, 10, 20, 30, 40]
            },
            sortBy: [],
            sortDesc: [true, true, true, true],
            timeFormat: 'h:mm a'
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
    },
    methods: {
        filterTable(data) {
            return data.map((item) => {
                let isActive = item.alertId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            staticChoices: (state) => state.app.staticChoices,
            siteSections: (state) => state.app.siteSections
        }),

        isDashboard() {
            return this.source === 'dashboard'
        }
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
        },
    }
}
</script>