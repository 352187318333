var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-alert__table editable_table flex flex-col w-full"},[(!_vm.loading)?_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableDataArr,"footer-props":_vm.footerProps,"data-cy":"alertList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText},scopedSlots:_vm._u([{key:"item.staffName",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.staffName)+" ")])]}},{key:"item.numCompleted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numCompleted)+" ")]}},{key:"item.numIncomplete",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numIncomplete)+" ")]}},{key:"item.numOverdue",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex"},[_c('div',{class:item.numOverdue === 0 ? "" : "bg-red-light text-red text-xs font-bold p-2 rounded leading-3"},[_vm._v(" "+_vm._s(item.numOverdue)+" ")])])]}},{key:"item.clientEpisodes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.numClientsWithOpenEpisodes)+" ")]}}],null,false,3086056469)}):_c('div',{staticClass:"flex items-center justify-center h-20 w-full"},[_c('Loader',{staticClass:"w-12 h-12"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }