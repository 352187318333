var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-alert__table editable_table flex flex-col w-full",attrs:{"id":"myassessments"}},[(!_vm.loading)?_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableDataArr,"footer-props":_vm.footerProps,"data-cy":"alertList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.formTitle",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.formTitle)+" ")]),_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(item.location + ' ' + item.program)+" ")])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"truncate w-130"},[_vm._v(" "+_vm._s(item.firstName +' '+ item.lastName)+" ")])]}},{key:"item.started",fn:function(ref){
var item = ref.item;
return [(item.started)?_c('fa-icon',{staticClass:"text-sm text-black text-green",attrs:{"icon":"check"}}):_c('fa-icon',{staticClass:"text-sm text-black text-red",attrs:{"icon":"times"}})]}},{key:"item.staff",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.workerName)+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-1 mb-1"},[_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.dueDate))+" ")]),_c('div',{staticClass:"mt-1 flex"},[(_vm.checkDate(item.dueDate) === 'OVERDUE')?_c('div',{staticClass:"bg-red-light text-red text-xs font-bold p-2 rounded leading-3"},[_vm._v(" OVERDUE ")]):(_vm.checkDate(item.dueDate) === 'TODAY')?_c('div',{staticClass:"bg-yellow-light text-yellow text-xs font-bold p-2 rounded leading-3"},[_vm._v(" TODAY ")]):_vm._e()])])]}},(!_vm.disabled)?{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [(_vm.isDashboard)?_c('div',{staticClass:"text-right tablet-hide",class:{'active-tr': item.active}},[_c('a',{staticClass:"hover:text-primary mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rowClickHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"chevron-right"}})],1)]):_vm._e()]}}:null],null,true)}):_c('div',{staticClass:"flex items-center justify-center h-20 w-full"},[_c('Loader',{staticClass:"w-12 h-12"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }