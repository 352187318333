<template>
    <div class="col-span-1 rounded p-4 l-box-shadow bg-white-pure">
        <div class="flex items-center justify-space-between">
            <div class="flex">
                <ClientsSvg class="text-primary-light w-10 mr-2" />
                <h1 class="text-black text-xl lg:text-2xl">My Assessments</h1>
            </div>
            <AssessmentFilter @setAssessmentFilter="setAssessmentFilter" />
        </div>
        <AssessmentTable
            :table-data="assessmentsData"
            :headers="headersAssessmentTable"
            :source="source"
            :loading="loading"
            :items-per-page="itemsPerPage"
        />
    </div>
</template>
<script>
import AssessmentFilter from '@/components/assessment/partials/AssessmentFilter'
import AssessmentTable from '@/components/assessment/partials/AssessmentTable'
import ClientsSvg from '@/assets/icons/nav/icons_clients.svg'
export default {
    name: "Assessments",
    props: {
        headersAssessmentTable: {
            type: Array,
            default: () => []
        },
        assessmentsData: {
            type: Array,
            default: () => []
        },
        source: {
            type: String,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    components: {
        ClientsSvg, AssessmentTable, AssessmentFilter
    },
    emits: ['setAssessmentFilter'],
    setup(_, { emit }) {
        const setAssessmentFilter = (dataFilter) => {
            emit('setAssessmentFilter', dataFilter)
        }
        return {
            setAssessmentFilter
        }
    }
}
</script>