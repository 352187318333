<template>
    <div class="flex flex-col a-dashboard px-6">
        <div class="mt-8">
            <div class="grid grids-cols-1 xl:grid-cols-2 gap-5 ">
                <StaffAssessments 
                    :staff-assessments-data="staffAssessmentsData"
                    :headersStaffAssessmentTable="headersStaffAssessmentTable"
                    :source="source"
                    :loading="loadingStaffAssessmentsData"
                    :items-per-page="itemsPerPage"
                    @setStaffAssessmentFilter="setStaffAssessmentFilter"
                    v-if="$can('view', 'staff_assessments')"
                />
                <Assessments
                    :assessments-data="assessmentsData"
                    :headersAssessmentTable="headersAssessmentTable"
                    :source="source"
                    :loading="loadingAssessmentsData"
                    @setAssessmentFilter="setAssessmentFilter"
                />
                <div class="col-span-1 rounded p-4 l-box-shadow bg-white-pure">
                    <div class="flex items-center">
                        <RecordAlertIcon class="text-primary-light w-10 mr-2" />
                        <h1 class="text-black text-xl lg:text-2xl">Alerts</h1>
                    </div>
                    <AlertTable
                        :table-data="alerts"
                        :headers="headers"
                        :source="source"
                        :items-per-page="itemsPerPage"
                        @alert-mutated="refreshAlerts"
                    />
                </div>

                <div class="rounded p-4 l-box-shadow bg-white-pure hidden">
                    <div class="flex items-center">
                        <ClientsSvg class="text-primary-light w-10 mr-2" />
                        <h1 class="text-black text-xl lg:text-2xl">New Sign Ups</h1>
                    </div>
                    
                    <div class="mt-4">New mobile app users pending approval</div>
                    
                </div>
                
                <!-- Hiding widgets below as they have not been implemented, and probably won't be in the first cut. -->

                <!-- <div class="rounded p-4 l-box-shadow bg-white-pure">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center">
                            <ClientsSvg class="text-primary-light w-10 mr-2" />
                            <h1 class="text-black text-xl lg:text-2xl">Average Treatment Progress</h1>
                        </div>
                        
                        <router-link
                            :to="{name: 'episode-list'}"
                            class="h-4 group flex flex-row text-xs text-primary-light uppercase hover:text-primary-dark flex-right"
                            data-cy="addPracticeLink"
                        >
                            <span class="font-bold group-hover:text-primary-dark mr-1 ">More</span>
                            <fa-icon icon="chevron-right" class="h-4 mr-1 group-hover:text-primary-dark" />
                        </router-link>
                    </div>
                    <div class="mt-4">
                        Graph that displays the progress (averages) for the clients assigned to that worker.
                    </div>
                    
                    
                </div>
                <div class="rounded p-4 l-box-shadow bg-white-pure">
                    <div class="flex items-center">
                        <ReportSvg class="text-primary-light w-10 mr-2" />
                        <h1 class="text-black text-xl lg:text-2xl">Forms Due</h1>
                    </div>
                    <div class="mt-4">Any assessments/forms with due dates for client episodes the logged in user is assigned to AND is INCOMPLETE status. Sort by due date column. Action button to add to calendar</div>

                    Episode table filtered by logged in user as assigned worker (dont display filter dropdowns, table should be already filtered, user cant edit filters on Dashboard)
                   
                   Columns = Date range, Status, Client, site section, last column to display a button if an assessment/or form is due (with due date and sort on this last column)
                    
                    
                    <ClientList
                        @updated-practice-list="updateClientList"
                        :page-size="5"
                        :hide-title="true"
                        :summary="true"
                        class="a-dashboard-client-list"
                    />
                    
                    <EpisodeTable
                        :table-data="episodes"
                        :items-per-page="pagination.limit"
                        :total="total"
                        :loading="loading"
                        @update-pagination="updatePagination"
                        ref="episodeTable"
                    />
                </div>
                <div class="rounded p-4 l-box-shadow bg-white-pure">
                    <div class="flex items-center">
                        <CalendarIcon class="text-primary-light w-10 mr-2" />
                        <h1 class="text-black text-xl lg:text-2xl">My Calendar</h1>
                    </div>
                    
                    <div class="mt-4">Appointments + assessments due</div>
                    <Calendar />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FirstLogin from '@/components/clients/partials/FirstLogin'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import ClientList from '@/components/clients/Clients'
import Calendar from '@/components/appointments/partials/Calendar'
import StaffAssessments from '@/components/staff-assessment/StaffAssessments'
import Assessments from '@/components/assessment/Assessments'
import AlertTable from '@/components/alert/partials/AlertTable'
import ClientsSvg from '@/assets/icons/nav/icons_clients.svg'
import SurveyIcon from '@/assets/icons/icons_survey.svg'
import ReportSvg from '@/assets/icons/nav/icons_reports.svg'
import RecordAlertIcon from '@/assets/icons/records/icon_alerts.svg'
import CalendarIcon from '@/assets/icons/icon_calendar.svg'
import GET_EPISODES from '@/graphql/queries/getEpisodes.gql'
import EpisodeTable from '@/components/episodes/partials/EpisodeTable'
import GET_ALERTS from "@/graphql/queries/getAlerts.gql";
import axiosInstance from '@/utils/axios'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import {format} from 'date-fns'

export default {
    name: "Dashboard",
    components: {
        FirstLogin, ClientList, Calendar, AlertTable,
        ClientsSvg, ReportSvg, RecordAlertIcon, CalendarIcon,
        EpisodeTable, SurveyIcon, Assessments, StaffAssessments
    },
    mixins: [ AuthMixin, BackendHelpers, HttpMixin ],
    data() {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        return {
            roles: [],
            indicatorResources: [],
            surveyVersionIds: [],
            resources: [],
            clientList: [],
            itemsPerPage: 5,
            staffAssessment: [],
            staffAssessmentFilter: {
                "startDate": format(firstDay, 'Y-MM-dd'),
                "endDate": format(lastDay, 'Y-MM-dd'),
                "locationProgramId": null,
                "assessmentStatus" : 'ALL'
            },
            assessment: [],
            assessmentFilter: {
                "startDate": null,
                "endDate": null,
                "locationProgramId":null,
                "staff" : null
            },
            alerts: [],
            episodes: [],
            total: 0,
            source: this.$route.name,
            staffAssessmentsData: [],
            assessmentsData: [],
            loadingStaffAssessmentsData: true,
            loadingAssessmentsData: true,
            pagination: {
                offset: 0,
                limit: 10,
            },
            filter: {
                locationProgram: null,
                status: null,
                worker: null
            },
            headersStaffAssessmentTable: [
                { text: 'Staff', align: 'left', value: 'staffName', class: 'w-150 count--header', sortable: true },
                { text: 'Completed', align: 'left', value: 'numCompleted', sortable: true },
                { text: 'Incomplete', align: 'left', value: 'numIncomplete', class: 'count--header', sortable: true },
                { text: 'Overdue', align: 'left', value: 'numOverdue', sortable: true },
                { text: 'Clients with open episodes', align: 'left', value: 'numClientsWithOpenEpisodes', fixed: true, sortable: true},
            ],
            headers: [
                /*{ text: 'Date/TIme', align: 'left', value: 'timestamp', class: 'name--header', sortable: false },*/
                { text: 'Level', align: 'left', value: 'level', class: 'w-150 count--header', sortable: false },
                { text: 'Type', align: 'left', value: 'type', class: 'w-200 count--header', sortable: false },
                { text: 'Client', align: 'left', value: 'name', class: 'count--header', sortable: false },
                { text: 'Episode', align: 'left', value: 'episode', sortable: false },
                { text: '', align: 'left', value: 'editRecord', fixed: true, sortable: false},
            ],
            headersAssessmentTable: this.$can('view', 'staff_assessments') ? [
                { text: 'Assessment', align: 'left', value: 'formTitle', class: 'w-210 count--header', sortable: false },
                { text: 'Client', align: 'left', value: 'client', class: 'count--header', sortable: false },
                { text: 'Started', align: 'left', value: 'started', class: 'count--header', sortable: false },
                { text: 'Staff', align: 'left', value: 'staff', class: 'count--header', sortable: false },
                { text: 'Due Date', align: 'left', value: 'dueDate', class: 'count--header', fixed: true, sortable: true },
                { text: '', align: 'left', value: 'editRecord', fixed: true, sortable: false},
            ] : [
                { text: 'Assessment', align: 'left', value: 'formTitle', class: 'w-200 count--header', sortable: false },
                { text: 'Client', align: 'left', value: 'client', class: 'count--header', sortable: false },
                { text: 'Started', align: 'left', value: 'started', class: 'count--header', sortable: false },
                { text: 'Due Date', align: 'left', value: 'dueDate', class: 'count--header', fixed: true, sortable: true },
                { text: '', align: 'left', value: 'editRecord', fixed: true, sortable: false},
            ]
        }
    },
    created() {
        this.getStaffAssessment()
        this.getAssessment()
    },
    apollo: {
        episodes: {
            query: GET_EPISODES,
            variables() {
                const {status, ...pagination} = this.pagination
                const statusCompare = this.status ? { _eq: this.status } : {}
                // we don't set the locationProgramsCompare since we don't user it here
                const locationProgramsCompare = {}
                const workerCompare = this.loggedInUser.userId ? { _eq: this.loggedInUser.userId } : {}
                return {
                    ...pagination,
                    statusCompare,
                    locationProgramsCompare,
                    workerCompare
                }
            },
            result({ data: { episodes, total } }) {
                this.total = total.aggregate.count
                this.episodes = camelcaseKeys(episodes || [], { deep: true })
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            },
        },
        alerts: {
            query: GET_ALERTS,
            variables () {
                const locationProgramsCompare = {
                    _in: this.loggedInUser.locationProgramIds
                }
                return {
                    locationProgramsCompare
                }
            },
            result({ data: { alerts } }) {
                let convertedAlerts = camelcaseKeys(alerts, {deep: true})
                this.alerts = [...convertedAlerts]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        }
    },
    methods: {
        setStaffAssessmentFilter(staffAssessmentFilter) {
            this.staffAssessmentFilter = staffAssessmentFilter
            this.getStaffAssessment()
        },
        async getStaffAssessment() {
            try {
                this.loadingStaffAssessmentsData = true
                const data = await axiosInstance.post(
                    `/reports/user/${this.loggedInUser.userId}/my_staff_assessments/`,
                    snakecaseKeys(this.staffAssessmentFilter)
                )
                this.staffAssessmentsData = camelcaseKeys(data.data)
                this.loadingStaffAssessmentsData = false
            } catch (error) {
                this.staffAssessmentsData = []
                this.loadingStaffAssessmentsData = false
            }
        },
        setAssessmentFilter(assessmentFilter) {
            this.assessmentFilter = assessmentFilter
            this.getAssessment()
        },
        async getAssessment() {
            try {
                this.loadingAssessmentsData = true
                const data = await axiosInstance.post(
                    `/reports/user/${this.loggedInUser.userId}/my_incomplete_assessments/`,
                    snakecaseKeys(this.assessmentFilter)
                )
                this.assessmentsData = camelcaseKeys(data.data)
                this.loadingAssessmentsData = false
            } catch (error) {
                this.assessmentsData = []
                this.loadingAssessmentsData = false
            }
        },
        async updateClientList(clientList) {
            this.loading = false
            this.clientList = clientList
        },
        updatePagination({ page, itemsPerPage }) {
            if (itemsPerPage * (page - 1) > this.total) return

            this.pagination.limit = itemsPerPage
            this.pagination.offset = this.pagination.limit * (page - 1)
        },
        resetPage() {
            this.pagination.offset = 0
            this.$refs.episodeTable.setPage(1)
        },
        resetFiltersHandler() {
            this.filter.locationProgram = null
            this.filter.status = null
            this.filter.worker = null
        },
        refreshAlerts() {
            this.$apollo.queries.alerts.refetch()
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser
        })
    }
}
</script>
