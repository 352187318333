<template>
    <div class="mx-auto max-w-960 py-6">
        <h1 class="text-black text-xl lg:text-2xl pb-4" data-cy="practiceName">Welcome to Connect</h1>
        <div class="flex flex-row items-center">
            <div class="flex-grow pr-12">
                <p>
                    Connect is your online tool for practice accreditation management. 
                </p>
                <p class="my-4">
                    You currently have no practices added. 
                    <br/>To get started click 'Add my practice' below.
                </p>
                <div class="mt-6 p-4 bg-yellow-light rounded">
                    <p class="mb-2"><b class="text-black">Before you add your practice, you must have the following:</b></p>
                    <ul class="text-sm list-disc pl-6">
                        <li>
                            A copy or photo of the practice's current accreditation certificate <br/>(date of expiry must be displayed clearly)
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex w-2/5">
                <img src="@/assets/images/image_welcomelanding.png" alt="Welcome to Connect" class="block" />
            </div>
        </div>

        <div class="w-7/12 m-auto hidden">
            <div class="grid grid-cols-5 px-2 welcome-icon-grid mt-20">
                <div class="col-span-1 text-center">
                    <a  href="#"
                        data-cy="addPracticeLink"
                        @click.prevent="addNewPractice"
                    >
                        <div class="text-black text-xs pt-2 px-2">Add my practice</div>
                    </a>
                </div>
                <div class="col-span-1 text-center">
                    <div class="text-xs pt-2 px-2 text-grey-2">Pay program fees</div>
                </div>
                

            </div>
        </div>

        <div class="text-center w-full mt-12">
            <el-button
                    class="uppercase font-display"
                    data-cy="addPracticeLink"
                    type="default"
                    @click.prevent="addNewPractice"
            >
                Get indicative fee
            </el-button>
            <el-button
                    class="uppercase font-display"
                    data-cy="addPracticeLink"
                    type="success"
                    @click.prevent="addNewPractice"
            >
                Add my practice <fa-icon icon="chevron-right" class="text-white-pure ml-3" />
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FirstLogin',
    computed: {
        name() {
            return this.$auth.user != null ? this.$auth.user.name : ''
        },
    },
    methods: {
        addNewPractice() {
            this.$router.push({'name': 'add-client'})
        }
    },
}
</script>
