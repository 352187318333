<template>
    <div class="c-alert__table editable_table flex flex-col w-full" id="myassessments">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            v-if="!loading"
            :headers="headers"
            :items="tableDataArr"
            :footer-props="footerProps"
            class="a-table w-full"
            data-cy="alertList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :no-data-text="emptyText"
            :no-results-text="emptyText"
            @click:row="rowClickHandler"
        >
            <template v-slot:item.formTitle="{item}">
                <div>
                    {{ item.formTitle }}
                </div>
                <div style="font-size: 10px">
                    {{ item.location + ' ' + item.program }}
                </div>
            </template>
            <template v-slot:item.client="{item}">
                <div class="truncate w-130">
                    {{ item.firstName +' '+ item.lastName }}
                </div>
            </template>
            <template v-slot:item.started="{item}" class="flex justify-space-around align-center">
                <fa-icon icon="check" class="text-sm text-black text-green" v-if="item.started" />
                <fa-icon icon="times" class="text-sm text-black text-red" v-else />
            </template>
            <template v-slot:item.staff="{item}">
                {{ item.workerName }}
            </template>
            <!-- <template v-slot:item.siteSection="{item}">
                {{ item.location + ' ' + item.program }}
            </template> -->
            <template v-slot:item.dueDate="{item}" class="flex">
                <div class="mt-1 mb-1">
                    <div>
                        {{ formatDate(item.dueDate) }}
                    </div>
                    

                    <div class="mt-1 flex"> 
                        <div class="bg-red-light text-red text-xs font-bold p-2 rounded leading-3" v-if="checkDate(item.dueDate) === 'OVERDUE'">
                            OVERDUE
                        </div>
                        <div class="bg-yellow-light text-yellow text-xs font-bold p-2 rounded leading-3" v-else-if="checkDate(item.dueDate) === 'TODAY'">
                            TODAY
                        </div>
                    </div>

                </div>
                
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{item}" v-if="!disabled">
                <div
                    class="text-right tablet-hide"
                    :class="{'active-tr': item.active}"
                    v-if="isDashboard"
                >
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
        </v-data-table>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {FormEvents, FormRules} from '@/components/shared/mixins/formMixins'
import Loader from '@/assets/loader.svg'
import {EpisodeHelpers} from '@/components/shared/mixins/episodeMixins'
import {ClientHelpers} from '@/components/shared/mixins/clientMixins'
import CheckboxCell from '@/components/partials/CheckboxCell'
import EditableCell from '@/components/partials/EditableCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import DatetimePickerCell from '@/components/partials/DatetimePickerCell'
import SelectCell from '@/components/partials/SelectCell'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {format} from 'date-fns'
import {SharedMixin} from '@/helpers/displayValue'

export default {
    name: 'AlertTable',
    components: {CheckboxCell, DatePickerCell, DatetimePickerCell, SelectCell, EditableCell, Loader},
    mixins: [FormRules, FormEvents, MessageDialog, EpisodeHelpers, ClientHelpers, SharedMixin],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        headers: {
            type: Array,
            default: () => []
        },
        source: {
            type: String,
            default: ''
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No incomplete assessments found',
            duration: 3000,
            type: '',
            message: '',
            page: 1,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            clientId: '',
            footerProps: {
                'items-per-page-options': [5, 10, 20, 30, 40]
            },
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                'formTitle',
                // 'order',
                'clientId',
                'started',
                'due_date',
                'desc'
            ],
            defaultAlert: {
                formTitle: null,
                clientId: null,
                started: null,
                dueDate: format(new Date(), 'yyyy-MM-dd'),
                desc: null
            },
            timeFormat: 'h:mm a'
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
    },
    methods: {
        formatDate(dueDate) {
            const date = new Date(dueDate)
            const monthNames = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ]
            return (
                date.getDate() +
                ' ' +
                monthNames[date.getMonth()] +
                ' ' +
                date.getFullYear().toString().substring(2)
            )
        },
        checkDate(dueDate) {
            const date = new Date(format(new Date(dueDate), 'yyyy-MM-dd 00:00:00'))
            const now = new Date(format(new Date(), 'yyyy-MM-dd 00:00:00'))
            if(date.getTime() < now.getTime())
                return 'OVERDUE'
            else if(date.getTime() === now.getTime())
                return 'TODAY'
            else
                return ''
        },
        rowClickHandler(obj, e) {
            this.currentRow = obj
            if (this.isDashboard) {
                if (obj.formAnswerId === 'None' || obj.formAnswerId === null) {
                    this.$router
                        .push({
                            name: 'episodes', 
                            params: {clientId: obj.clientId}
                        })
                        .catch((err) => err)
                } else {
                    this.$store.commit('SET_CURRENT_EPISODE', obj.episodeId)
                    this.$store.commit('SET_ASSIGNED_TO_ME', false)
                    this.$router
                        .push({
                            name: 'client-form',
                            params: {
                                clientId: obj.clientId,
                                episodeId: obj.episodeId,
                                formSetId: obj.formSetId
                            },
                            query: { fai: obj.formAnswerId }
                        })
                        .catch((err) => err)
                }
            }
            e.select(true)
        },
        filterTable(data) {
            return data.map((item) => {
                let isActive = item.alertId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if (!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map((item) => {
                    if (item.alertId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter((item) => {
                    if (item.alertId !== this.activeRowId) return item
                })
            }
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            staticChoices: (state) => state.app.staticChoices,
            siteSections: (state) => state.app.siteSections
        }),

        types() {
            return this.staticChoices['alert.types']
        },
        levels() {
            return this.staticChoices['alert.levels']
        },
        isDashboard() {
            return this.source === 'dashboard'
        }
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
