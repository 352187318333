<template>
    <div class="col-span-1 rounded p-4 l-box-shadow bg-white-pure">
        <div class="flex items-center justify-space-between">
            <div class="flex">
                <SurveyIcon class="text-primary-light w-10 mr-2" />
                <h1 class="text-black text-xl lg:text-2xl">Staff Assessments</h1>
            </div>
            <StaffAssessmentFilter @setStaffAssessmentFilter="setStaffAssessmentFilter" />
        </div>
        <StaffAssessmentTable
            :table-data="staffAssessmentsData"
            :headers="headersStaffAssessmentTable"
            :source="source"
            :loading="loading"
            :items-per-page="itemsPerPage"
        />
    </div>
</template>
<script>
import SurveyIcon from '@/assets/icons/icons_survey.svg'
import StaffAssessmentFilter from '@/components/staff-assessment/partials/StaffAssessmentFilter'
import StaffAssessmentTable from '@/components/staff-assessment/partials/StaffAssessmentTable'
export default {
    name: "StaffAssessments",
    props: {
        headersStaffAssessmentTable: {
            type: Array,
            default: () => []
        },
        staffAssessmentsData: {
            type: Array,
            default: () => []
        },
        source: {
            type: String,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        loading: {
            type: Boolean,
            default: true
        }
    },
    components: {
        StaffAssessmentTable, StaffAssessmentFilter, SurveyIcon
    },
    emits: ['setStaffAssessmentFilter'],
    setup(_, { emit }) {
        const setStaffAssessmentFilter = (dataFilter) => {
            emit('setStaffAssessmentFilter', dataFilter)
        }
        return {
            setStaffAssessmentFilter
        }
    }
}
</script>